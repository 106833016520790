<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 post-detail">
          <div class="post-detail__title">
            <h1 class="post-title">{{ post.title }}</h1>
          </div>
          <div class="post-detail__createdAt">
            <p>{{ post.modifiedAt }}</p>
          </div>
          <div class="post-detail__thumbnail">
            <b-img :src="url + post.thumbnail"></b-img>
          </div>
          <div class="post-detail__content" v-html="post.content">
            <!-- <p> {{post.content}}</p> -->
          </div>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import axiosConfig from "@/libs/axiosConfig";

export default {
  name: "post-detail",
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      post: {},
      id: this.$route.params.post_id,
    };
  },
  components: {
    LargeLayout,
  },
  mounted() {
    axiosConfig
      .get("/post/" + this.id)
      .then((response) => {
        this.post = response.data;
      })
      .catch((error) => {
        console.log("Error", error);
      });
  },
};
</script>

<style lang="scss">
.post-detail {
  padding: 20px;
  .post-detail__thumbnail {
    img {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  .post-detail__title {
    .post-title {
      font-weight: bold;
      text-transform: uppercase;
      color: var(--post-title-blue);
      @media screen and (max-width: 767px ) {
        font-size: 24px !important;
      }
    }
  }
}
.ql-video {
  width: 630px;
  height: 350px;
  @media screen and(max-width: 767px) {
    width: 100%;
    height: 230px;
  }
}
</style>
